
import mAxiosApi from "@/api";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted} from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Loader",
  setup() {
    setCurrentPageBreadcrumbs("Général", ["Pages", "Profile"]);

    const router = useRouter();

    onMounted(async () => {
      if (router.currentRoute.value.params.otherparam) {
        await mAxiosApi.prototype.getAxios("/setUserID/" + router.currentRoute.value.params.otherparam);
        router.push({ name: "profile-overview" });
      }
    });
  }
});
